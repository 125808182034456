<template>
    <ion-header>
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <div class="row align-center">
                <slot name="before">
                    <div class="shrink" :style="!DaDangKySuDungLenh ? 'opacity: 0' : ''">
                        <DxButton
                            type="default"
                            icon="mdi mdi-chevron-left"
                            class="ml-1"
                            @click="QuayLaiTrang($route.query.prePath)"
                            v-if="$route.query.prePath"
                        />
                        <DxButton
                            type="default"
                            icon="mdi mdi-close"
                            class="ml-1"
                            @click="QuayLaiDanhSachLenh"
                            :disabled="!DaDangKySuDungLenh"
                            v-else
                        />
                    </div>
                </slot>
                <slot name="center">
                    <div class="grow text-xs-center">LỆNH VẬN CHUYỂN</div>
                </slot>
                <slot name="after">
                    <div class="shrink" style="opacity: 0">
                        <DxButton
                            type="default"
                            icon="mdi mdi-chevron-left"
                            class="ml-1"
                        /></div
                ></slot>
            </div>
        </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="false">
        <div
            v-if="!DaDangKySuDungLenh"
            class="row pa-2 pt-3 justify-center align-center text-xs-center font-16 font-medium"
            style="height: calc(100vh - 48px - 36px); position: relative"
        >
            Bạn chưa đăng ký sử dụng dịch vụ Lệnh điện tử. Vui lòng liên hệ Công ty của
            bạn để được cập nhật!
        </div>
        <div v-else-if="!LoadXongDuLieu"></div>

        <div
            class="row pa-2 pt-3"
            style="height: calc(100vh - 48px - 36px); position: relative"
            v-else-if="realModel.ChiTietLenh?.maLenh && LoadXongDuLieu"
        >
            <!-- <div
                class="trang-thai"
                :style="` background-color: ${model.MaMau}; border-color: ${model.MaMau};`"
                v-if="model.TrangThai"
            >
                {{ model.IdTrangThai }}
            </div> -->
            <div
                class="trang-thai"
                :style="` background-color: ${realModel.MaMau}; border-color: ${realModel.MaMau};`"
                v-if="realModel.IdTrangThai == $t('TrangThaiLenh.KhongThucHienHanhTrinh')"
            >
                Không thực hiện
            </div>
            <div
                class="trang-thai"
                :style="` background-color: ${realModel.MaMau}; border-color: ${realModel.MaMau};`"
                v-else-if="
                    realModel.IdTrangThai == $t('TrangThaiLenh.KhongHoanThanhHanhTrinh')
                "
            >
                Không hoàn thành
            </div>
            <div
                class="trang-thai"
                :style="` background-color: ${realModel.MaMau}; border-color: ${realModel.MaMau};`"
                v-else-if="realModel.IdTrangThai == $t('TrangThaiLenh.HoanThanh')"
            >
                Đã hoàn thành
            </div>
            <div
                class="trang-thai"
                :style="` background-color: ${realModel.MaMau}; border-color: ${realModel.MaMau};`"
                v-else-if="
                    realModel.IdTrangThai == $t('TrangThaiLenh.KhongDuDieuKienXuatBen')
                "
            >
                Không đủ điều kiện
            </div>
            <div
                class="trang-thai"
                :style="` background-color: ${realModel.MaMau}; border-color: ${realModel.MaMau};`"
                v-else
            >
                {{ realModel.TrangThai }}
            </div>

            <div class="xs12">
                <div
                    class="row font-16 font-medium text-xs-center justify-center text-link"
                    @click="dialogBanTheHienLenh = true"
                >
                    {{ realModel.ChiTietLenh?.maLenh }}
                </div>
                <div class="row my-1" v-if="laLenhGiay">
                    <div class="xs12">
                        <div
                            class="row justify-center text-xs-center font-16 font-medium"
                        >
                            MÃ CỦA CĐBVN CẤP:
                        </div>
                        <div
                            class="row justify-center text-xs-center font-20 font-bold color-success"
                        >
                            {{ realModel.ChiTietLenh.maCDBCap }}
                        </div>
                    </div>
                </div>
                <div class="row font-16 font-medium text-xs-center justify-center">
                    Bến đi: {{ realModel.TenBenDi }}
                </div>

                <div
                    v-if="!laLenhGiay"
                    class="row justify-center align-center qrcodes"
                    @click="dialogBanTheHienLenh = true"
                >
                    <img
                        v-if="realModel && realModel.ChiTietLenh.qrCode"
                        :src="QrCodeLink"
                        style="
                            width: 224px !important;
                            height: 224px !important;
                            max-width: unset;
                        "
                        alt=""
                    />
                </div>
                <!-- <div class="row justify-center align-center mt-2 mb-2">
                    <DxButton
                        text="Lưu lệnh vào máy"
                        styling-mode="text"
                        stylingMode="container"
                        type="default"
                        icon="mdi mdi-download"
                        @click="downloadImg()"
                    />
                </div>
                <div
                    class="
                        row
                        justify-center
                        font-italic
                        mb-2
                        text-xs-center
                        color-warning
                    "
                    style="font-size: 12px"
                >
                    (Nên lưu lệnh sau khi bến ký, phòng khi mất sóng)
                </div> -->
                <div class="row font-medium justify-center" style="font-size: 28px">
                    {{ realModel.BienSo }}
                </div>

                <div class="row justify-center font-italic mb-2">
                    {{ $Helper.formatDateTime(realModel.GioXuatBenKeHoach) }}
                </div>

                <div class="row mt-2 mb-1 px-2 font-16 font-medium">
                    Chi tiết chuyến xe
                </div>

                <div :class="row" style="line-height: 1">
                    <i class="mdi mdi-circle-small"></i>
                    <div :class="textLeft">Mã tuyến</div>
                    <div :class="textRight">
                        {{ realModel.MaTuyen }}
                    </div>
                </div>
                <div :class="row" style="line-height: 1">
                    <i class="mdi mdi-circle-small"></i>
                    <div :class="textLeft">Tên bến đi</div>
                    <div :class="textRight">
                        {{ realModel.TenBenDi }}
                    </div>
                </div>
                <div :class="row" style="line-height: 1">
                    <i class="mdi mdi-circle-small"></i>
                    <div :class="textLeft">Tên bến đến</div>
                    <div :class="textRight">
                        {{ realModel.TenBenDen }}
                    </div>
                </div>
                <div class="row mt-2 mb-1 px-2 font-16 font-medium">Chi tiết bán vé</div>
                <div :class="row" style="line-height: 1">
                    <i class="mdi mdi-circle-small"></i>
                    <div :class="textLeft" class="color-primary">Đã mua vé</div>
                    <div :class="textRight" class="color-primary">
                        {{ realModel.SoKhachDaMuaVe || 0 }}
                    </div>
                </div>
                <div :class="row" style="line-height: 1">
                    <i class="mdi mdi-circle-small"></i>
                    <div :class="textLeft" class="color-success">Đang trên xe</div>
                    <div :class="textRight" class="color-success">
                        {{ realModel.SoKhachDangTrenXe || 0 }}
                    </div>
                </div>
            </div>

            <!-- <div
                    v-if="chuyenDiGanDay"
                    class="fab-float-button row justify-center align-center"
                >
                    <DxButton
                        text="Đến bến"
                        type="danger"
                        styling-mode="text"
                        icon="mdi mdi-bus-stop"
                        
                    />
                </div> -->
        </div>

        <div
            class="row pa-2 pt-3 justify-center align-center text-xs-center font-16 font-medium"
            style="height: calc(100vh - 48px - 36px); position: relative"
            v-else
        >
            Không tìm thấy thông tin Lệnh hiện tại!
        </div>
        <DxSpeedDialAction
            :visible="!laLenhGiay && DieuKienTiepNhanLenh"
            :index="1"
            icon="mdi mdi-hand-okay"
            label="Tiếp nhận lệnh"
            @click="TiepNhanLenh"
        />
        <DxSpeedDialAction
            :visible="!laLenhGiay && DieuKienChuyenDoiLenh"
            :index="2"
            icon="mdi mdi-file-replace-outline"
            label="Chuyển đổi lệnh"
            @click="
                $router.push({
                    path: '/Chuyen-Doi-Lenh',
                    query: { idLenhDienTu: realModel.guidLenh },
                })
            "
        />
        <DxSpeedDialAction
            :visible="!laLenhGiay && DieuKienXacNhanKetThucChuyenDi"
            :index="3"
            icon="mdi mdi-bus-stop"
            label="Xác nhận kết thúc chuyến đi"
            @click="
                ParamThongBao = {
                    state: 'Warning',
                    title: 'Xác nhận xe đã đến bến',
                    message: 'Bạn có chắc chắn đã kết thúc chuyến đi?',
                };
                dialogKetThucChuyenDi = true;
            "
        />

        <DxSpeedDialAction
            :visible="!laLenhGiay && DieuKienDungHanhTrinh"
            :index="4"
            icon="mdi mdi-stop-circle"
            label="Xác nhận xe gặp sự cố"
            @click="dialogDungHanhTrinh = true"
        />

        <DxSpeedDialAction
            :visible="
                !laLenhGiay &&
                hiddenFloatBtn &&
                realModel.ChiTietLenh?.maLenh &&
                !realModel.ChiTietLenh?.laiXeDaHoanThanhHanhTrinh &&
                (DieuKienTiepNhanLenh ||
                    DieuKienChuyenDoiLenh ||
                    DieuKienXacNhanKetThucChuyenDi ||
                    DieuKienDungHanhTrinh)
            "
            :index="5"
            icon="mdi hiddenBtn"
            :onContentReady="hiddenFloatButton"
            label=""
        />
    </ion-content>

    <PopupVue
        height="auto"
        v-model:dialog="dialogKetThucChuyenDi"
        title=""
        buttonTextLeft="Hủy"
        class="popup-padding-8"
        @close="xacNhanKetThucChuyenDi"
        heightScrollView="auto"
    >
        <template #content>
            <ThongBaoVue
                :message="ParamThongBao.message"
                :state="ParamThongBao.state"
                :title="ParamThongBao.title"
            />
        </template>
    </PopupVue>

    <!-- ====================================Popup Dừng hành trình ========================================= -->
    <PopupVue
        height="auto"
        v-model:dialog="dialogDungHanhTrinh"
        title="Xác nhận xe gặp sự cố"
        buttonTextRight="Xác nhận"
        buttonTextLeft="Hủy"
        classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16 popup-padding-top-16"
        @close="closeDungHanhTrinh"
    >
        <template #content>
            <PopupDungHanhTrinhVue
                ref="DungHanhTrinh"
                :statePopup="dialogDungHanhTrinh"
                :lenhDangThucHien="ChiTietLenhDangThucHien"
            />
        </template>
    </PopupVue>

    <!-- Thông báo lỗi -->
    <PopupVue
        height="auto"
        v-model:dialog="dialogThongBao"
        title=""
        :useButtonLeft="false"
        @close="
            () => {
                dialogThongBao = false;
            }
        "
        heightScrollView="auto"
    >
        <template #content>
            <ThongBaoVue
                :message="ParamThongBao.message"
                :state="ParamThongBao.state"
                :title="ParamThongBao.title"
            />
        </template>
    </PopupVue>
    <PopupBanTheHienLenhVue
        :dialog="dialogBanTheHienLenh"
        :guidLenh="realModel.ChiTietLenh?.guidLenh"
        :laLenhGiay="laLenhGiay"
        :danhSachLenhGiay="realModel.ChiTietLenh?.danhSachBanTheHien || []"
        @closes="
            () => {
                dialogBanTheHienLenh = false;
            }
        "
    />
</template>

<script>
import config from "devextreme/core/config";
import repaintFloatingActionButton from "devextreme/ui/speed_dial_action/repaint_floating_action_button";
import { IonContent, IonToolbar, IonHeader } from "@ionic/vue";
import { DxButton, DxSpeedDialAction } from "devextreme-vue";
import LaiXe from "../../../../class/LaiXe";
import ThongBaoVue from "../../../components/_Common/ThongBao.vue";
import PopupVue from "../../../components/_Common/Popup.vue";
import PopupDungHanhTrinhVue from "./PopupDungHanhTrinh.vue";
import PopupBanTheHienLenhVue from "./PopupBanTheHienLenh.vue";
import LenhDienTu from "../../../../class/LenhDienTu";
import { mapState } from "vuex";
export default {
    components: {
        IonContent,
        IonToolbar,
        IonHeader,
        DxButton,
        ThongBaoVue,
        PopupVue,
        DxSpeedDialAction,
        PopupDungHanhTrinhVue,
        PopupBanTheHienLenhVue,
    },
    props: {
        isChiTiet: { type: Boolean, default: false },
        DaDangKySuDungLenh: { type: Boolean, default: false },
        laLenhGiay: { type: Boolean, default: false },
        LoadXongDuLieu: { type: Boolean, default: false },
        hiddenFloatBtn: { type: Boolean, default: true },
        MaChuyenDi: { type: String, default: "" },
        ChiTietLenhDangThucHien: { type: Object, default: {} },
        model: {
            type: Object,
            default: {
                ChiTietLenh: {},
                MaTuyen: "",
                TenBenDi: "",
                TenBenDen: "",
                SoKhachDaMuaVe: 0,
                SoKhachDangTrenXe: 0,
                TrangThai: "",
                BienSo: null,
                GioXuatBenKeHoach: new Date(),
                MaMau: "#000",
                IdTrangThai: null,
                guidLenh: "",
            },
        },
        modelDangThucHien: {
            type: Object,
            default: {
                ChiTietLenh: {},
                MaTuyen: "",
                TenBenDi: "",
                TenBenDen: "",
                SoKhachDaMuaVe: 0,
                SoKhachDangTrenXe: 0,
                TrangThai: "",
                BienSo: null,
                GioXuatBenKeHoach: new Date(),
                MaMau: "#000",
                IdTrangThai: null,
                guidLenh: "",
            },
        },
    },
    data() {
        return {
            dialogBanTheHienLenh: false,
            guidChuyenDi: null,
            qrcode: null,
            textLeft: "xs5 font-14 font-regular",
            textRight: "xs7 font-14 pl-3 font-medium text-xs-right",
            row: "row px-4 align-center ",
            dialogThongBao: false,
            dialogKetThucChuyenDi: false,
            dialogTiepNhanLenh: false,
            dialogDungHanhTrinh: false,
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
        realModel: {
            get() {
                return this.model && this.model.BienSo
                    ? this.model
                    : this.modelDangThucHien;
            },
        },
        QrCodeLink() {
            if (!this.realModel.ChiTietLenh) return "#";
            let fcolor = this.renderColorQr(this.realModel.ChiTietLenh?.laBenDi);
            let qrdata = this.realModel.ChiTietLenh?.qrCode;
            return (
                `https://image-charts.com/chart?` +
                [
                    "cht=qr",
                    "icqrb=FFFFFF00",
                    "icqrf=" + fcolor,
                    "chs=244x244",
                    "choe=UTF-8",
                    "chld=L",
                    "chl=" + qrdata,
                ].join("&")
            );
        },
        DieuKienTiepNhanLenh() {
            return (
                this.hiddenFloatBtn &&
                !this.realModel.ChiTietLenh?.laiXeDaNhanLenh &&
                this.realModel.ChiTietLenh?.idTrangThai !=
                    this.$t("TrangThaiLenh.KhongThucHienHanhTrinh") &&
                this.realModel.ChiTietLenh?.idTrangThai !=
                    this.$t("TrangThaiLenh.KhongHoanThanhHanhTrinh") &&
                this.modelDangThucHien?.ChiTietLenh?.guidLenh !=
                    this.realModel?.ChiTietLenh?.guidLenh
            );
        },
        DieuKienChuyenDoiLenh() {
            return (
                this.hiddenFloatBtn &&
                this.realModel.ChiTietLenh?.maLenh &&
                (this.realModel.ChiTietLenh?.idTrangThai ==
                    this.$t("TrangThaiLenh.ChoBenDiKy") ||
                    this.realModel.ChiTietLenh?.idTrangThai ==
                        this.$t("TrangThaiLenh.DangThucHienHanhTrinh")) &&
                this.modelDangThucHien.ChiTietLenh?.guidLenh ==
                    this.realModel.ChiTietLenh?.guidLenh
            );
        },
        DieuKienXacNhanKetThucChuyenDi() {
            return (
                this.hiddenFloatBtn &&
                this.realModel.ChiTietLenh?.maLenh &&
                this.realModel.ChiTietLenh?.idTrangThai !=
                    this.$t("TrangThaiLenh.ChoBenDiKy") &&
                !this.realModel.ChiTietLenh?.laiXeDaHoanThanhHanhTrinh &&
                this.modelDangThucHien.ChiTietLenh?.guidLenh ==
                    this.realModel.ChiTietLenh?.guidLenh
            );
        },
        DieuKienDungHanhTrinh() {
            return (
                this.hiddenFloatBtn &&
                this.realModel.ChiTietLenh?.maLenh &&
                this.realModel.ChiTietLenh?.idTrangThai !=
                    this.$t("TrangThaiLenh.HoanThanh") &&
                this.realModel.ChiTietLenh?.idTrangThai !=
                    this.$t("TrangThaiLenh.KhongThucHienHanhTrinh") &&
                this.realModel.ChiTietLenh?.idTrangThai !=
                    this.$t("TrangThaiLenh.KhongHoanThanhHanhTrinh") &&
                this.modelDangThucHien.ChiTietLenh?.guidLenh ==
                    this.realModel.ChiTietLenh?.guidLenh
            );
        },
    },
    watch: {},
    methods: {
        toastThongBao(type, message) {
            let toast = {
                message: message,
                type: type,
                visible: true,
            };
            this.emitter.emit("onToast", toast);
        },
        thongBao(state, title, message) {
            this.ParamThongBao = {
                state: state,
                title: title,
                message: message,
            };
            this.dialogThongBao = true;
        },
        thongBaoLoi(message, log) {
            this.dialogKetThucChuyenDi = false;
            this.thongBao("Error", message || "Đã xảy ra lỗi. Vui lòng thử lại");
        },
        async xacNhanKetThucChuyenDi(param) {
            if (!param) {
                this.dialogKetThucChuyenDi = false;
                return;
            }
            let toast = {
                type: "error",
                message: "",
                visible: true,
            };
            try {
                let dataPost = {
                    idLenh: this.realModel.ChiTietLenh.guidLenh,
                    ToaDo: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                };
                let rs = await this.$store.dispatch("Lenh/KetThucChuyenDi", dataPost);
                if (rs.Data.status == true) {
                    this.dialogKetThucChuyenDi = false;
                    this.toastThongBao("success", "Kết thúc chuyến đi thành công!");
                    this.$router.push({
                        path: "/DanhSachLenh",
                    });
                } else {
                    toast.message = rs.Data.message;
                    this.emitter.emit("onToast", toast);
                }
            } catch (error) {
                console.log("🚀 = error", error);
                toast.message = "Lỗi hệ thống!";
                this.emitter.emit("onToast", toast);
            }
        },
        async capNhatTrang() {
            this.getData();
        },
        hiddenFloatButton() {
            let elm = document.querySelectorAll(".hiddenBtn");
            if (elm.length > 0) {
                setTimeout(() => {
                    elm.forEach((e) => {
                        e.parentElement.parentElement.style.display = "none";
                    });
                }, 10);
            }
        },
        async closeDungHanhTrinh(param) {
            if (!param || !this.$refs.DungHanhTrinh) {
                this.dialogDungHanhTrinh = false;
                return;
            }
            try {
                let valid = this.$refs.DungHanhTrinh.$refs.formValidation.instance.validate();
                if (!valid.isValid) {
                    this.$Helper.autoFocusError(valid);
                    return;
                }
                let laiXe = new LaiXe();
                laiXe.maLenh = this.$refs.DungHanhTrinh.model.Malenh;
                laiXe.lyDo = this.$refs.DungHanhTrinh.model.LyDo;
                laiXe.danhSachHinhAnh = this.$refs.DungHanhTrinh.model.DanhSachHinhAnh;
                // Thực hiện dừng hành trình
                let rs = await laiXe.dungHanhTrinh(
                    this.realModel.ChiTietLenh.guidLenh,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                this.dialogDungHanhTrinh = false;
                if (!rs) {
                    throw new Error();
                }
                if (!rs.Data.status) {
                    return this.toastThongBao(
                        "error",
                        "Xác nhận xe gặp sự cố thất bại! " + rs.Data.message,
                    );
                }
                this.$router.push({
                    path: "/thanh-cong",
                    query: {
                        title: "Đã xác nhận xe gặp sự cố",
                        message: `Thông báo sẽ được gửi đến đơn vị ${this.realModel.ChiTietLenh.tenDoanhNghiep}!`,
                        guidLenh: this.realModel.ChiTietLenh.guidLenh,
                    },
                });
            } catch (error) {
                console.log("🚀 error", error);
                this.thongBaoLoi(error);
            }
        },
        QuayLaiDanhSachLenh() {
            this.$emit("QuayLaiDanhSachLenh");
        },
        QuayLaiTrang(path) {
            console.log("🚀 ~ file: ChiTietLenh.vue:632 ~ QuayLaiTrang ~ path:", path);
            this.$router.push(path);
        },
        renderColorQr(isBenDi) {
            if (isBenDi) {
                return "03a9f4";
            } else {
                return "13b000";
            }
        },
        async downloadImg() {
            let lenh = new LenhDienTu();
            lenh.guidLenh = this.realModel.ChiTietLenh.guidLenh;
            let link = await lenh.layLinkXemBanTheHienLenh();
            let domain = "https://slaixe.sbus.vn/tai-lenh/";
            return (
                SonPhat.hasOwnProperty("openExternal") &&
                SonPhat.openExternal(domain + `?link=${link}`)
            );
        },
        async TiepNhanLenh() {
            return this.$emit("NhanLenh", this.realModel.ChiTietLenh);
            try {
                this.$startLoading;
                let rs = await new LaiXe().tiepNhanLenh(
                    this.realModel.ChiTietLenh.guidLenh,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                this.dialogTiepNhanLenh = false;
                this.$stopLoading;
                if (!rs) {
                    throw this.thongBaoLoi("Lỗi hệ thống!");
                }
                if (!rs.Data.status) {
                    return this.thongBaoLoi(rs.Data.message);
                }
                this.$emit("reload", this.realModel.ChiTietLenh.guidLenh);
                this.$emit("layChiTietLenhDangThucHienAction");
                this.thongBaoThanhCong("Tiếp nhận lệnh thành công!");
            } catch (error) {
                this.$stopLoading;
                this.thongBaoLoi("Lỗi hệ thống!");
            }
        },
        thongBaoThanhCong(message) {
            let toast = {
                message: message,
                type: "success",
                visible: true,
            };
            this.emitter.emit("onToast", toast);
        },
        thongBaoLoi(message) {
            let toast = {
                message: message,
                type: "error",
                visible: true,
            };
            this.emitter.emit("onToast", toast);
        },
    },
    created() {},
    mounted() {
        config({
            floatingActionButtonConfig: {
                icon: "mdi mdi-menu",
                position: {
                    at: "right bottom",
                    my: "right bottom",
                    offset: "-16 -44",
                },
                shading: true,
            },
        });
        repaintFloatingActionButton();
        this.emitter.on("hiddenFloatBtn", (bool) => {
            this.$emit("update:hiddenFloatBtn", bool);
        });
    },
};
</script>

<style scoped>
>>> .custom-toolbar .toolbar-container {
    height: 48px;
}
.qrcodes {
    position: relative;
    /* max-width: 180px;
    max-height: 180px; */
    height: 188px;
    width: 100%;
    overflow: hidden;
}

.trang-thai {
    position: absolute;
    top: 44px;
    right: -60px;
    transform: rotate(45deg);
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 4px 0;
    width: 224px;
    text-align: center;
    z-index: 1;
    opacity: 0.8;
    color: #fff;
}
>>> .fab-float-button .dx-button {
    border-radius: 50px;
}
</style>

<style lang="scss" scoped>
.fab-float-button {
    box-shadow: $box_shadow;
    border-radius: 50px;
    width: max-content;
    // height: 32px;
    position: fixed;
    right: 16px;
    bottom: 8px;
    z-index: 10;
    background-color: white;
}
.fab-float-button:active {
    opacity: 0.7;
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .fab-float-button {
        bottom: 48px;
    }
}
</style>
